import React, { Component, Fragment } from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"

import { ContextProvider } from "../components/Context"
import { ContextConsumer } from "../components/Context"
// import Follow from "../components/Follow"
import Map from "../components/Map"
import Hierarchy from "../components/Hierarchy"

import Logo from "../modules/Logo"
import LogoCompact from "../modules/LogoCompact"
import ScrollUp from "../modules/ScrollUp"
import Button from "../modules/Button"

import { slugify } from "../functions/slugify"

// import metadata from "../data/metadata.json"

// Scroll effect to anchors
if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]', {
    // offset: 60,
  })
}

export default class Layout extends Component {
  state = { ready: false }

  componentDidMount() {
    // Load UIkit framework
    if (typeof window !== "undefined") {
      require("../styles/uikit.theme.css")
      require("../styles/custom.css")
      const UIkit = require("uikit")
      const Icons = require("uikit/dist/js/uikit-icons.min")
      UIkit.use(Icons)
      this.UIkit = UIkit // enable using this.UIkit inside component
      UIkit.sticky("#sticky")
      if (UIkit) {
        this.setState({ ready: true })
      }
    }
    // END : Load UIkit framework
  }

  render() {
    const { children } = this.props

    return (
      <ContextProvider>
        {/* LOADER */}

        {/* {!this.state.ready && (
          <div
            style={{
              position: "fixed",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: "999",
              backgroundColor: "#3c3c3c",
            }}
          >
            <div
              style={{
                color: "white",
                textAlign: "center",
                paddingTop: "50vh",
              }}
            >
              Chargement en cours...
            </div>
          </div>
        )} */}

        {/* END : LOADER */}

        {/* SCROLL PANEL */}

        <div
          className="uk-position-relative uk-width-1-2@m"
          id="top"
          css={css`
            @media (max-width: 960px) {
              z-index: 1;
              margin-bottom: 35vh;
            }
            @media (min-width: 960px) {
              z-index: 10;
              box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.8);
              float: left;
            }
          `}
        >
          {children}

          <div className="uk-overlay uk-overlay-primary uk-light">
            {/* <hr className="uk-margin-remove" /> */}
            <ScrollUp to={"#top"} />
          </div>

          {/* FOOTER */}

          <div className="uk-overlay uk-overlay-primary uk-light">
            <div className="uk-section">
              <div className="uk-container uk-container-small uk-animation-fade">
                <a
                  data-uk-toggle="target: #aboutModal"
                  className="uk-flex uk-flex-center"
                >
                  <div className="uk-margin-large uk-button uk-button-text">
                    <div className="uk-padding-small">
                      <div style={{ width: "120px" }}>
                        <Logo />
                      </div>
                    </div>
                    <div>utiliser l'application</div>
                  </div>
                </a>
                <div id="aboutModal" className="uk-flex-top" data-uk-modal>
                  <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-width-medium uk-background-primary uk-light">
                    <button
                      className="uk-modal-close-outside"
                      type="button"
                      data-uk-close
                    ></button>

                    {/* <div style={{ width: "200px" }}> */}
                    <Logo />
                    {/* </div> */}

                    <p className="uk-text-center">
                      Mapistory est un outil cartographique permettant de
                      partager ses voyages, histoires et autres aventures...
                    </p>
                    <p className="uk-text-center">
                      Si vous souhaitez utiliser cette application pour vos
                      projets, n'hésitez pas à{" "}
                      <a
                        href="https://pro.guslyon.fr/contact/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        me contacter
                      </a>
                    </p>
                    <p className="uk-text-center">
                      <small>
                        Plus d'informations :{" "}
                        <a
                          href="https://pro.guslyon.fr/projects/app-mapistory/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          www.guslyon.fr
                        </a>
                      </small>
                    </p>
                  </div>
                </div>

                <div
                  className="uk-grid-divider uk-grid-small uk-child-width-auto uk-flex uk-flex-center"
                  data-uk-grid
                >
                  <div>
                    <span>Copyright &copy; {new Date().getFullYear()}</span>
                  </div>

                  <div>
                    <span>
                      Développement web :{" "}
                      <a
                        href="https://www.pro.guslyon.fr"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        GusLyon.fr
                      </a>
                    </span>
                  </div>
                  {this.props.path === "/" && (
                    <div>
                      <span>Photo accueil : Dorine VIAL</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* END : FOOTER */}
        </div>

        {/* END : SCROLL PANEL */}

        {/* FIXED PANEL */}

        <div
          className="uk-position-fixed uk-width-1-2@m uk-flex uk-flex-column"
          css={css`
            @media (max-width: 960px) {
              z-index: 10;
              height: 35vh;
              top: 65vh;
              box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.8);
            }
            @media (min-width: 960px) {
              z-index: 1;
              height: 100vh;
              top: 0;
              right: 0;
            }
          `}
        >
          <ContextConsumer>
            {({ data }) => {
              const { mapdata, header } = data
              return (
                <Fragment>
                  {/* MAP */}

                  {mapdata && (
                    <div className="uk-section-default uk-flex-auto uk-position-relative">
                      <Map
                        bbox={mapdata.bbox}
                        toggle3d={this.state.toggle3d}
                        toggleZoom={this.state.toggleZoom}
                        toggleFitBounds={this.state.toggleFitBounds}
                      />
                    </div>
                  )}

                  {/* END : MAP */}

                  {/* MAP TOP LEFT */}

                  <div className="uk-position-top-left uk-padding-small uk-flex uk-flex-column">
                    {header && (
                      <Fragment>
                        {header.currentType !== "blog" && (
                          <Link
                            to={"/"}
                            className="uk-icon-button uk-button-secondary uk-margin-small-bottom"
                          >
                            <span className="material-icons-outlined">
                              home
                            </span>
                          </Link>
                        )}
                        {header.eventLink && (
                          <Link
                            to={header.eventLink}
                            className="uk-icon-button uk-button-secondary uk-margin-small-bottom"
                          >
                            <span className="material-icons-outlined">
                              public
                            </span>
                          </Link>
                        )}
                        {header.partLink && (
                          <Link
                            to={header.partLink}
                            className="uk-icon-button uk-button-secondary uk-margin-small-bottom"
                          >
                            <span className="material-icons-outlined">
                              timeline
                            </span>
                          </Link>
                        )}

                        {header.currentType === "blog" && (
                          <div
                            onClick={() => {
                              this.setState({
                                toggleFitBounds: !this.state.toggleFitBounds,
                              })
                            }}
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex={0}
                            className="uk-icon-button uk-link uk-button-primary uk-margin-small-bottom"
                          >
                            <span className="material-icons-outlined">
                              home
                            </span>
                          </div>
                        )}
                        {header.currentType === "event" && (
                          <div
                            onClick={() => {
                              this.setState({
                                toggleFitBounds: !this.state.toggleFitBounds,
                              })
                            }}
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex={0}
                            className="uk-icon-button uk-link uk-button-primary uk-margin-small-bottom"
                          >
                            <span className="material-icons-outlined">
                              public
                            </span>
                          </div>
                        )}
                        {header.currentType === "part" && (
                          <div
                            onClick={() => {
                              this.setState({
                                toggleFitBounds: !this.state.toggleFitBounds,
                              })
                            }}
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex={0}
                            className="uk-icon-button uk-link uk-button-primary uk-margin-small-bottom"
                          >
                            <span className="material-icons-outlined">
                              timeline
                            </span>
                          </div>
                        )}
                        {header.currentType === "day" && (
                          <div
                            onClick={() => {
                              this.setState({
                                toggleFitBounds: !this.state.toggleFitBounds,
                              })
                            }}
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex={0}
                            className="uk-icon-button uk-link uk-button-primary uk-margin-small-bottom"
                          >
                            <span className="material-icons-outlined">
                              calendar_today
                            </span>
                          </div>
                        )}
                      </Fragment>
                    )}
                  </div>

                  {/* END : MAP TOP LEFT */}

                  {/* MAP TOP RIGHT */}

                  <div className="uk-position-top-right uk-padding-small uk-flex uk-flex-column">
                    <div
                      onClick={() => {
                        this.setState({
                          toggleZoom: "in",
                        })
                      }}
                      onKeyDown={() => {}}
                      role="button"
                      tabIndex={0}
                      className="uk-icon-button uk-link uk-button-secondary uk-margin-small-bottom"
                    >
                      <span className="material-icons-outlined">add</span>
                    </div>
                    <div
                      onClick={() => {
                        this.setState({
                          toggleZoom: "out",
                        })
                      }}
                      onKeyDown={() => {}}
                      role="button"
                      tabIndex={0}
                      className="uk-icon-button uk-link uk-button-secondary uk-margin-small-bottom"
                    >
                      <span className="material-icons-outlined">remove</span>
                    </div>
                    <div
                      onClick={() =>
                        this.setState({
                          toggle3d: !this.state.toggle3d,
                        })
                      }
                      onKeyDown={() => {}}
                      role="button"
                      tabIndex={0}
                      className="uk-icon-button uk-link uk-button-secondary uk-margin-small-bottom"
                    >
                      <span className="material-icons-outlined">
                        3d_rotation
                      </span>
                    </div>
                  </div>

                  {/* END : MAP TOP RIGHT */}

                  {/* STATS */}

                  {mapdata &&
                    (mapdata.duration ||
                      mapdata.distance ||
                      mapdata.ascending ||
                      mapdata.descending) && (
                      <div
                        className="uk-position-bottom uk-overlay uk-overlay-default uk-text-center uk-padding-small uk-text-bold uk-flex uk-flex-around uk-child-width-expand"
                        style={{ zIndex: 10 }}
                      >
                        {mapdata.duration && (
                          <div className="uk-flex uk-flex-middle uk-flex-center">
                            <span className="material-icons-outlined uk-margin-small-right uk-flex-none uk-visible@s">
                              hourglass_empty
                            </span>
                            <span className="uk-text-truncate">
                              {Math.round(mapdata.duration)} heures
                            </span>
                          </div>
                        )}
                        {mapdata.distance && (
                          <div className="uk-flex uk-flex-middle uk-flex-center">
                            <span className="material-icons-outlined uk-margin-small-right uk-flex-none uk-visible@s">
                              directions_walk
                            </span>
                            <span className="uk-text-truncate">
                              {Math.round(mapdata.distance)} Km
                            </span>
                          </div>
                        )}

                        {mapdata.ascending && (
                          <div className="uk-flex uk-flex-middle uk-flex-center">
                            <span className="material-icons-outlined uk-margin-small-right uk-flex-none uk-visible@s">
                              trending_up
                            </span>
                            <span className="uk-text-truncate">
                              +{Math.round(mapdata.ascending / 10) * 10} m
                            </span>
                          </div>
                        )}
                        {mapdata.descending && (
                          <div className="uk-flex uk-flex-middle uk-flex-center">
                            <span className="material-icons-outlined uk-margin-small-right uk-flex-none uk-visible@s">
                              trending_down
                            </span>
                            <span className="uk-text-truncate">
                              -{Math.round(mapdata.descending / 10) * 10} m
                            </span>
                          </div>
                        )}
                      </div>
                    )}

                  {/* END : STATS */}
                </Fragment>
              )
            }}
          </ContextConsumer>
        </div>

        {/* END : FIXED PANEL */}

        {/* NAVIGATION */}

        <ContextConsumer>
          {({ data }) => {
            const { header } = data
            return (
              <Fragment>
                {header && (
                  <Fragment>
                    {/* Top Navbar */}

                    <div
                      className="uk-position-fixed uk-width-1-2@m"
                      style={{
                        zIndex: 20,
                        top: 0,
                        left: 0,
                      }}
                    >
                      <div
                        className="uk-position-absolute uk-width-1-1 uk-background-secondary gus-shadow"
                        data-uk-parallax="opacity: 0,1; viewport: 0.5"
                        style={{ height: "60px" }}
                      />
                      <nav
                        id="top-navbar"
                        className="uk-navbar-container uk-navbar-transparent uk-light uk-flex uk-flex-middle uk-flex-center uk-position-relative uk-position-z-index"
                        style={{ height: "60px" }}
                      >
                        <div className="uk-navbar-left uk-width-expand">
                          <div className="uk-flex uk-flex-middle uk-width-auto">
                            {/* Off-canvas */}
                            <div id="menu" data-uk-offcanvas="mode: none">
                              <div className="uk-offcanvas-bar uk-padding-remove gus-shadow">
                                <button
                                  className="uk-offcanvas-close"
                                  type="button"
                                  data-uk-close
                                ></button>
                                <div
                                  className="uk-flex uk-flex-middle uk-flex-left uk-width-auto"
                                  style={{ height: "60px" }}
                                >
                                  {/* Off-canvas logo */}

                                  <div
                                    className="uk-navbar-toggle uk-flex-none uk-button uk-button-link"
                                    data-uk-navbar-toggle-icon
                                    data-uk-toggle="target: #menu"
                                  />

                                  <Link to="/" className="uk-flex-none">
                                    <div style={{ width: "120px" }}>
                                      <Logo />
                                    </div>
                                  </Link>
                                </div>
                                {/* END : Off-canvas logo */}

                                {/* END : Off-canvas menu */}
                                <div className="uk-padding-small">
                                  <Hierarchy path={this.props.path} />
                                </div>
                                {/* END : Off-canvas menu */}
                              </div>
                            </div>
                            {/* END : Off-canvas */}
                            {/* Navbar logo */}
                            <div
                              className="uk-navbar-toggle uk-flex-none uk-button uk-button-link"
                              data-uk-navbar-toggle-icon
                              data-uk-toggle="target: #menu"
                            />
                            <Link to="/" className="uk-flex-none">
                              <div
                                className="uk-hidden@xl"
                                style={{ width: "38px" }}
                              >
                                <LogoCompact />
                              </div>
                              <div
                                className="uk-visible@xl"
                                style={{ width: "120px" }}
                              >
                                <Logo />
                              </div>
                            </Link>
                            {/* END : Navbar logo */}

                            {/* Breadcrumbs */}
                            {this.props.path !== "/" && (
                              <h6 className="uk-text-truncate uk-margin-remove uk-text-bold">
                                {header.eventName && header.eventLink && (
                                  <Fragment>
                                    <span
                                      className="uk-margin-small-left uk-margin-small-right"
                                      data-uk-icon="icon: chevron-right;"
                                    />

                                    <Link
                                      to={header.eventLink}
                                      className="uk-text-uppercase uk-link-text"
                                    >
                                      {header.eventName}
                                    </Link>
                                  </Fragment>
                                )}

                                {header.partName && header.partLink && (
                                  <Fragment>
                                    <span
                                      className="uk-margin-small-left uk-margin-small-right"
                                      data-uk-icon="icon: chevron-right;"
                                    />

                                    <Link
                                      to={header.partLink}
                                      className="uk-text-uppercase uk-link-text"
                                    >
                                      {header.partName}
                                    </Link>
                                  </Fragment>
                                )}

                                {header.currentName && (
                                  <Fragment>
                                    <span
                                      className="uk-margin-small-left uk-margin-small-right"
                                      data-uk-icon="icon: chevron-right;"
                                    />
                                    <span className="uk-text-uppercase uk-text-muted">
                                      {header.currentName}
                                    </span>
                                  </Fragment>
                                )}
                              </h6>
                            )}
                            {/* END : Breadcrumbs */}
                          </div>
                        </div>

                        {/* <div className="uk-navbar-right">
                          <Follow compact={true} />
                        </div> */}
                      </nav>
                    </div>

                    {/* END : Top Navbar */}

                    {/* Bottom Navbar */}

                    {header.buttons && header.counts && (
                      <div
                        className="uk-position-fixed uk-width-1-2@m"
                        style={{ zIndex: 10, left: 0, top: 0 }}
                      >
                        <nav
                          className="uk-navbar-container uk-navbar-transparent uk-background-primary gus-shadow uk-light uk-flex uk-flex-middle uk-flex-center uk-position-relative uk-position-z-index"
                          data-uk-parallax="target: #parallax-menu; y: 60; opacity: 0,1; viewport: 1"
                          data-uk-scrollspy-nav="closest: li; offset: 10; cls: uk-active"
                          style={{ height: "60px", marginBottom: "40px" }}
                        >
                          <ul className="uk-flex uk-flex-around uk-width-expand uk-child-width-expand uk-margin-small uk-list">
                            <li className="uk-hidden">
                              <a href="#top">TOP</a>
                            </li>
                            {header.buttons.events && (
                              <li>
                                <Button
                                  icon={"public"}
                                  text={header.buttons.events}
                                  count={
                                    header.counts.events
                                      ? header.counts.events
                                      : null
                                  }
                                  url={"#" + slugify(header.buttons.events)}
                                />
                              </li>
                            )}

                            {header.buttons.description && (
                              <li>
                                <Button
                                  icon={"subject"}
                                  text={header.buttons.description}
                                  count={null}
                                  url={
                                    "#" + slugify(header.buttons.description)
                                  }
                                />
                              </li>
                            )}

                            {header.buttons.parts && (
                              <li>
                                <Button
                                  icon={"timeline"}
                                  text={header.buttons.parts}
                                  count={
                                    header.counts.parts
                                      ? header.counts.parts
                                      : null
                                  }
                                  url={"#" + slugify(header.buttons.parts)}
                                />
                              </li>
                            )}
                            {header.buttons.days && (
                              <li>
                                <Button
                                  icon={"calendar_today"}
                                  text={header.buttons.days}
                                  count={
                                    header.counts.days
                                      ? header.counts.days
                                      : null
                                  }
                                  url={"#" + slugify(header.buttons.days)}
                                />
                              </li>
                            )}

                            {header.buttons.photos && (
                              <li>
                                <Button
                                  icon={"photo_camera"}
                                  text={header.buttons.photos}
                                  count={
                                    header.counts.photos
                                      ? header.counts.photos
                                      : null
                                  }
                                  url={"#" + slugify(header.buttons.photos)}
                                />
                              </li>
                            )}
                            {header.buttons.comments && (
                              <li>
                                <Button
                                  icon={"mode_comment"}
                                  text={header.buttons.comments}
                                  count={
                                    header.counts.comments
                                      ? header.counts.comments
                                      : null
                                  }
                                  url={"#" + slugify(header.buttons.comments)}
                                />
                              </li>
                            )}
                          </ul>
                        </nav>
                      </div>
                    )}

                    {/* Bottom Navbar */}
                  </Fragment>
                )}
              </Fragment>
            )
          }}
        </ContextConsumer>

        {/* END : NAVIGATION */}
      </ContextProvider>
    )
  }
}
