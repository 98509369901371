import React, { Component, Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
// import ReactDOM from "react-dom"
// import { Link } from "gatsby"
import { navigate } from "@reach/router"

import "mapbox-gl/dist/mapbox-gl.css"
import mapboxgl from "mapbox-gl"
//import MapboxLanguage from "@mapbox/mapbox-gl-language"
import * as turf from "@turf/turf"

mapboxgl.accessToken = process.env.GATSBY_MAPBOX_ACCESS_KEY

class Map extends Component {
  state = {
    loading: true,
  }

  componentDidMount() {
    const {
      initialCollection,
      traceCollection,
      centroidCollection,
      endsCollection,
      bbox,
    } = this.props

    //////////////////////////
    // INITIAL MAP SETTINGS //
    //////////////////////////

    // Define map style
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      attributionControl: false,
      center: [2, 47],
      zoom: 2,
      style: {
        version: 8,
        sources: {},
        layers: [
          {
            id: "background",
            type: "background",
            paint: {
              "background-color": "white",
            },
          },
        ],
        glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
      },
      // style: "mapbox://styles/mapbox/light-v10?optimize=true",
      // style: "mapbox://styles/algous/cjxx58kib02c41cnt7q146iad", // light
      // style: "mapbox://styles/algous/cjxypzaik03kr1cs7m98tobnv" // hilleshade
      // style: "mapbox://styles/algous/cjyg38kqa01go1co07u1sc90y", // labels
    })
    // END : Define map style

    // Add map controls
    this.map.addControl(
      new mapboxgl.AttributionControl({
        compact: true,
      })
    )
    // this.map.addControl(
    //   new mapboxgl.NavigationControl({
    //     showCompass: false,
    //   })
    // )
    //this.map.addControl(new MapboxLanguage({ defaultLanguage: "fr" }))
    // END : Add map controls

    // this.map.scrollZoom.disable()

    this.map.loadImage(this.props.backgroundLabel, (error, image) => {
      if (error) throw error
      if (!this.map.hasImage("backgroundLabel")) {
        this.map.addImage("backgroundLabel", image, {
          content: [3, 3, 13, 13],
          stretchX: [[7, 9]],
          stretchY: [[7, 9]],
        })
      }
    })

    ////////////////////////////////
    // END : INITIAL MAP SETTINGS //
    ////////////////////////////////

    ////////////////////
    // ON MAP LOADING //
    ////////////////////

    this.map.on("load", () => {
      // Base map

      // this.map.addSource("baseMapSource", {
      //   type: "raster",
      //   url: "mapbox://mapbox.light",
      // })
      // this.map.addLayer({
      //   id: "baseMapLayer",
      //   type: "raster",
      //   source: "baseMapSource",
      //   // minzoom: 0,
      //   // maxzoom: 12,
      //   paint: {
      //     "raster-opacity": 1,
      //   },
      // })

      const rasterUrl =
        "https://tile.opentopomap.org/{z}/{x}/{y}.png"
      // "https://stamen-tiles.a.ssl.fastly.net/terrain/{z}/{x}/{y}.png"
      // "https://stamen-tiles.a.ssl.fastly.net/terrain-background/{z}/{x}/{y}.png"
      // const rasterUrl =
      //   "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"

      this.map.addSource("rasterSource", {
        type: "raster",
        tiles: [rasterUrl],
        tileSize: 256,
        // attribution: ".......",
      })
      this.map.addLayer({
        id: "rasterLayer",
        type: "raster",
        source: "rasterSource",
        // minzoom: 0,
        // maxzoom: 13,
        paint: {
          "raster-opacity": 0.5,
        },
      })

      // const labelsUrl =
      //   "https://stamen-tiles.a.ssl.fastly.net/toner-labels/{z}/{x}/{y}.png"

      // this.map.addSource("labelsSource", {
      //   type: "raster",
      //   tiles: [labelsUrl],
      //   tileSize: 256,
      //   // attribution: ".......",
      // })
      // this.map.addLayer({
      //   id: "labelsLayer",
      //   type: "raster",
      //   source: "labelsSource",
      //   // minzoom: 0,
      //   // maxzoom: 13,
      //   paint: {
      //     "raster-opacity": 1,
      //   },
      // })

      let zoomRasterUrl
      if (process.env.GATSBY_IGN_ACCESS_KEY) {
        zoomRasterUrl =
          "https://wxs.ign.fr/" +
          process.env.GATSBY_IGN_ACCESS_KEY +
          "/geoportail/wmts" +
          "?" +
          "SERVICE=WMTS" +
          "&" +
          "REQUEST=GetTile" +
          "&" +
          "VERSION=1.0.0" +
          "&" +
          "TILEMATRIXSET=PM" +
          "&" +
          "TILEMATRIX={z}" +
          "&" +
          "TILECOL={x}" +
          "&" +
          "TILEROW={y}" +
          "&" +
          "LAYER=GEOGRAPHICALGRIDSYSTEMS.MAPS" +
          "&" +
          "FORMAT=image/jpeg" +
          "&" +
          "STYLE=normal"
      } else {
        zoomRasterUrl = "https://tile.opentopomap.org/{z}/{x}/{y}.png"
      }

      this.map.addSource("geoportailSource", {
        type: "raster",
        tiles: [zoomRasterUrl],
        tileSize: 256,
        // attribution: "IGN Geoportail",
      })
      this.map.addLayer({
        id: "geoportailLayer",
        type: "raster",
        source: "geoportailSource",
        minzoom: 12,
        maxzoom: 20,
        paint: {
          "raster-opacity": 0.5,
        },
      })

      // this.map.addSource("maskSource", {
      //   type: "geojson",
      //   data: polyMask(mask, bounds),
      // })

      // this.map.addLayer({
      //   id: "maskLayer",
      //   source: "maskSource",
      //   type: "fill",
      //   paint: {
      //     "fill-color": "white",
      //     "fill-opacity": 0.999,
      //   },
      // })

      // ANIMATED LINE

      // let framesPerSecond = 240
      // let initialOpacity = 1
      // let opacity = initialOpacity
      // let initialWidth = 4
      // let width = initialWidth
      // let maxWidth = 40

      // const animateLine = () => {
      //   setTimeout(() => {
      //     requestAnimationFrame(animateLine)
      //     width += (maxWidth - width) / framesPerSecond
      //     opacity -= 0.9 / framesPerSecond
      //     this.map.setPaintProperty(
      //       "traceLayer1",
      //       "line-width",
      //       width <= 0 ? 0 : width
      //     )
      //     this.map.setPaintProperty(
      //       "traceLayer1",
      //       "line-opacity",
      //       opacity <= 0 ? 0 : opacity
      //     )
      //     if (opacity <= 0) {
      //       width = initialWidth
      //       opacity = initialOpacity
      //     }
      //   }, 1000 / framesPerSecond)
      // }
      // // Start the animation.
      // animateLine()

      // console.log("initialCollection", initialCollection.features)
      // console.log("traceCollection", traceCollection.features)

      this.map.addSource("initialSource", {
        type: "geojson",
        data: initialCollection,
      })
      this.map.addLayer({
        id: "backInitialLayer",
        type: "line",
        source: "initialSource",
        paint: {
          "line-width": 8,
          "line-color": "white",
        },
      })
      this.map.addLayer({
        id: "initialLayer",
        type: "line",
        source: "initialSource",
        paint: {
          "line-width": 4,
          "line-color": "darkgray",
        },
      })

      this.map.addSource("traceSource", {
        type: "geojson",
        data: traceCollection,
      })
      // this.map.addLayer({
      //   id: "traceLayer1",
      //   type: "line",
      //   source: "traceSource",
      //   paint: {
      //     "line-width": initialWidth,
      //     "line-width-transition": { duration: 0 },
      //     "line-opacity-transition": { duration: 0 },
      //     "line-color": "white",
      //   },
      // })
      this.map.addLayer({
        id: "hoverTraceLayer",
        type: "line",
        source: "traceSource",
        paint: {
          "line-width": 12,
          "line-color": "black",
        },
        filter: ["==", "id", "=="],
      })
      this.map.addLayer({
        id: "backTraceLayer",
        type: "line",
        source: "traceSource",
        paint: {
          "line-width": 8,
          "line-color": "white",
        },
      })
      this.map.addLayer({
        id: "traceLayer",
        type: "line",
        source: "traceSource",
        paint: {
          "line-width": 4,
          "line-color": "magenta",
        },
      })

      this.map.addSource("endsSource", {
        type: "geojson",
        data: endsCollection,
      })

      this.map.addLayer({
        id: "endsHoverLayer",
        type: "circle",
        source: "endsSource",
        paint: {
          "circle-color": "black",
          "circle-radius": 10,
        },
        filter: ["==", "id", "=="],
      })

      this.map.addLayer({
        id: "endsLayer",
        type: "circle",
        source: "endsSource",
        paint: {
          "circle-color": "chartreuse",
          "circle-radius": 6,
          "circle-stroke-width": 2,
          "circle-stroke-color": "white",
        },
        filter: ["==", "type", "event"],
      })

      this.map.addSource("centroidSource", {
        type: "geojson",
        data: centroidCollection,
      })
      this.map.addLayer({
        id: "centroidLayer",
        type: "symbol",
        source: "centroidSource",
        layout: {
          "text-font": ["Roboto Medium"],
          "text-field": ["get", "title"],
          "icon-image": "backgroundLabel",
          "icon-text-fit": "both",
        },
        paint: {
          "text-color": "black",
          // "text-halo-width": 2,
          // "text-halo-color": "rgba(0, 0, 0, 0.8)",
          // "text-halo-blur": 60,
        },
        filter: ["==", "type", "event"],
      })

      // this.map.on("click", "backTraceLayer", feature => {
      //   const { properties } = feature.features[0]
      //   navigate(properties.url)

      //   // // Add popup on click
      //   // const placeholder = document.createElement("div")
      //   // ReactDOM.render(
      //   //   <Link to={properties.url}>{properties.title}</Link>,
      //   //   placeholder
      //   // )
      //   // new mapboxgl.Popup()
      //   //   .setDOMContent(placeholder)
      //   //   .setLngLat(feature.lngLat)
      //   //   .addTo(map)
      //   // // END : Add popup on click
      // })

      this.map.on("click", "centroidLayer", feature => {
        const { properties } = feature.features[0]

        if (
          typeof window !== "undefined" &&
          new RegExp(properties.url).test(window.location.pathname)
        ) {
          this.fitToBbox(bbox, 4000)
        } else {
          navigate(properties.url)
        }
      })

      // Create track section and get coordinates (admin)
      if (
        typeof window !== "undefined" &&
        new RegExp("/admin").test(window.location.pathname)
      ) {
        this.map.on("mouseenter", "initialLayer", () => {
          this.map.getCanvas().style.cursor = "pointer"
        })
        this.map.on("mouseleave", "initialLayer", () => {
          this.map.getCanvas().style.cursor = ""
        })

        this.map.on("click", "initialLayer", initialFeatures => {
          const initialTrack = initialCollection.features.find(
            track =>
              track.properties.id === initialFeatures.features[0].properties.id
          )

          let allEventStopPoints = []
          const allEventExtremitiesCollection = endsCollection.features.filter(
            point => point.properties.type === "event"
          )
          allEventExtremitiesCollection.forEach(point => {
            allEventStopPoints = [
              ...allEventStopPoints,
              turf.point(point.geometry.coordinates[1], point.properties),
            ]
          })
          // console.log("e.lngLat", e.lngLat)
          // console.log("allEventExtremitiesCollection", allEventExtremitiesCollection)

          if (
            allEventStopPoints.length === allEventExtremitiesCollection.length
          ) {
            const { lng, lat } = initialFeatures.lngLat
            const targetPoint = turf.point([lng, lat])

            // Get start point
            let startPoint
            if (!this.state.drawPoints) {
              // const allEventStopPointsCollection = turf.featureCollection(
              //   allEventStopPoints
              // )
              // startPoint = turf.nearestPoint(
              //   targetPoint,
              //   allEventStopPointsCollection
              // )
              startPoint = allEventStopPoints.find(
                point => point.properties.id === initialTrack.properties.id
              )
            } else {
              startPoint = this.state.drawPoints.pop()
            }
            // console.log("startPoint", startPoint)

            // // Get nearest track
            // const nearestTrack = initialCollection.features.find(
            //   track => track.properties.id === startPoint.properties.id
            // )
            // // console.log("nearestTrack", nearestTrack)

            // Create new point
            // let stopPoint = turf.nearestPointOnLine(nearestTrack, targetPoint)
            // stopPoint.properties = nearestTrack.properties
            let stopPoint = turf.nearestPointOnLine(initialTrack, targetPoint)
            stopPoint.properties = initialTrack.properties
            let drawPoints
            if (!this.state.drawPoints) {
              drawPoints = [stopPoint]
            } else {
              drawPoints = [...this.state.drawPoints, stopPoint]
            }
            this.setState({
              drawPoints,
            })
            console.log("drawPoints", drawPoints)

            // Cut a section of the track
            const sectionTrack = turf.lineSlice(
              turf.getCoord(startPoint),
              turf.getCoord(stopPoint),
              // nearestTrack
              initialTrack
            )
            const drawLines = turf.featureCollection([sectionTrack])
            // console.log("drawLines", drawLines)

            // Draw new line
            if (this.map.getSource("drawLinesSource")) {
              this.map.removeLayer("drawLinesLayer")
              this.map.removeSource("drawLinesSource")
            }
            this.map.addSource("drawLinesSource", {
              type: "geojson",
              data: drawLines,
            })
            this.map.addLayer({
              id: "drawLinesLayer",
              type: "line",
              source: "drawLinesSource",
              paint: {
                "line-width": 4,
                "line-color": "orange",
              },
            })

            // Draw new point
            if (this.map.getSource("drawPointsSource")) {
              this.map.removeLayer("drawPointsLayer")
              this.map.removeSource("drawPointsSource")
            }
            this.map.addSource("drawPointsSource", {
              type: "geojson",
              data: turf.featureCollection(drawPoints),
            })
            this.map.addLayer({
              id: "drawPointsLayer",
              type: "circle",
              source: "drawPointsSource",
              paint: {
                "circle-color": "blue",
                "circle-radius": 6,
                "circle-stroke-width": 2,
                "circle-stroke-color": "white",
              },
            })
            // console.log("stopPoint", stopPoint)

            // Copy data to clipboard
            navigator.clipboard.writeText(
              JSON.stringify(sectionTrack.geometry.coordinates)
            )
            const UIkit = require("uikit")
            UIkit.notification({
              message: `<span uk-icon='icon: check'></span> Copied to clipboad : ${sectionTrack.geometry.coordinates.length} coords`,
              pos: "top-right",
              status: "success",
            })
          }
        })
      }
      // END : Create track section and get coordinates (admin)

      // Change cursor pointer on mouse over
      // this.map.on("mouseenter", "backTraceLayer", e => {
      //   this.map.getCanvas().style.cursor = "pointer"
      //   const { id } = e.features[0].properties
      //   this.map.setFilter("hoverTraceLayer", ["==", "id", id])
      // })
      // this.map.on("mouseleave", "backTraceLayer", () => {
      //   this.map.getCanvas().style.cursor = ""
      //   this.map.setFilter("hoverTraceLayer", ["==", "id", ""])
      // })
      this.map.on("mouseenter", "centroidLayer", e => {
        this.map.getCanvas().style.cursor = "pointer"
        const { id, type } = e.features[0].properties
        this.map.setFilter("endsHoverLayer", ["==", "id", id])
        type === "event" &&
          this.map.setFilter("hoverTraceLayer", ["==", "eventId", id])
        type === "part" &&
          this.map.setFilter("hoverTraceLayer", ["==", "partId", id])
        type === "day" &&
          this.map.setFilter("hoverTraceLayer", ["==", "id", id])
      })
      this.map.on("mouseleave", "centroidLayer", () => {
        this.map.getCanvas().style.cursor = ""
        this.map.setFilter("hoverTraceLayer", ["==", "id", ""])
        this.map.setFilter("endsHoverLayer", ["==", "id", ""])
      })

      this.map.on("moveend", () => {
        // console.clear()

        // // Get zoom level
        // console.log(this.map.getZoom())

        // Get map bounds
        const { _sw, _ne } = this.map.getBounds()

        // Create LngLat points
        const NE = [_ne.lng, _ne.lat]
        const SE = [_ne.lng, _sw.lat]
        const SW = [_sw.lng, _sw.lat]
        const NW = [_sw.lng, _ne.lat]

        // Convert LngLat to Pixel
        const NEpx = this.map.project(NE)
        const SEpx = this.map.project(SE)
        const SWpx = this.map.project(SW)
        const NWpx = this.map.project(NW)

        // Add px padding
        const paddingRatio = 0.05
        const nextNEpx = {
          x: NEpx.x - window.innerWidth * paddingRatio,
          y: NEpx.y + window.innerHeight * paddingRatio,
        }
        const nextSEpx = {
          x: SEpx.x - window.innerWidth * paddingRatio,
          y: SEpx.y - window.innerHeight * paddingRatio,
        }
        const nextSWpx = {
          x: SWpx.x + window.innerWidth * paddingRatio,
          y: SWpx.y - window.innerHeight * paddingRatio,
        }
        const nextNWpx = {
          x: NWpx.x + window.innerWidth * paddingRatio,
          y: NWpx.y + window.innerHeight * paddingRatio,
        }

        // Convert Pixel to LngLat
        let nextNE = this.map.unproject(nextNEpx)
        let nextSE = this.map.unproject(nextSEpx)
        let nextSW = this.map.unproject(nextSWpx)
        let nextNW = this.map.unproject(nextNWpx)
        nextNE = [nextNE.lng, nextNE.lat]
        nextSE = [nextSE.lng, nextSE.lat]
        nextSW = [nextSW.lng, nextSW.lat]
        nextNW = [nextNW.lng, nextNW.lat]

        // console.log("NE", NE, NEpx, nextNEpx, nextNE)
        // console.log("SE", SE, SEpx, nextSEpx, nextSE)
        // console.log("SW", SW, SWpx, nextSWpx, nextSW)
        // console.log("NW", NW, NWpx, nextNWpx, nextNW)

        const mapBoundsPolygon = turf.polygon([
          [nextNE, nextSE, nextSW, nextNW, nextNE],
        ])
        // console.log("mapBoundsPolygon", mapBoundsPolygon)
        // if (this.map.getSource("mapBoundsSource")) {
        //   this.map.removeLayer("mapBoundsLayer")
        //   this.map.removeSource("mapBoundsSource")
        // }
        // this.map.addSource("mapBoundsSource", {
        //   type: "geojson",
        //   data: mapBoundsPolygon,
        // })
        // this.map.addLayer({
        //   id: "mapBoundsLayer",
        //   type: "fill",
        //   source: "mapBoundsSource",
        //   layout: {},
        //   paint: {
        //     "fill-color": "#088",
        //     "fill-opacity": 0.5,
        //   },
        // })

        // const bboxPolygon = turf.bboxPolygon(bbox)
        // if (this.map.getSource("bboxPolygonSource")) {
        //   this.map.removeLayer("bboxPolygonLayer")
        //   this.map.removeSource("bboxPolygonSource")
        // }
        // this.map.addSource("bboxPolygonSource", {
        //   type: "geojson",
        //   data: bboxPolygon,
        // })
        // this.map.addLayer({
        //   id: "bboxPolygonLayer",
        //   type: "fill",
        //   source: "bboxPolygonSource",
        //   layout: {},
        //   paint: {
        //     "fill-color": "red",
        //     "fill-opacity": 0.5,
        //   },
        // })

        // console.log("centroidCollection", centroidCollection)

        const isInviewEvent = centroidCollection.features
          .filter(feature => feature.properties.type === "event")
          .some(feature =>
            turf.booleanWithin(feature.properties.bboxPolygon, mapBoundsPolygon)
          )
        // console.log("isInviewEvent", isInviewEvent)

        if (isInviewEvent) {
          this.map.setFilter("endsLayer", ["==", "type", "event"])
          this.map.setFilter("centroidLayer", ["==", "type", "event"])
        } else {
          const isInviewPart = centroidCollection.features
            .filter(feature => feature.properties.type === "part")
            .some(feature =>
              turf.booleanWithin(
                feature.properties.bboxPolygon,
                mapBoundsPolygon
              )
            )
          // console.log("isInviewPart", isInviewPart)

          // const inviewParts = centroidCollection.features.filter(
          //   feature =>
          //     feature.properties.type === "part" &&
          //     turf.booleanWithin(
          //       feature.properties.bboxPolygon,
          //       mapBoundsPolygon
          //     )
          // )
          // console.log("inviewParts", inviewParts)

          // if (inviewParts.length === 1) {
          //   if (
          //     typeof window !== "undefined" &&
          //     new RegExp(inviewParts[0].properties.url).test(
          //       window.location.pathname
          //     )
          //   ) {
          //     console.log("no url change...")
          //   } else {
          //     setTimeout(() => {
          //       console.log("change url to", inviewParts[0].properties.url)
          //       // navigate(inviewParts[0].properties.url) // <===================== TODO : ANIMATION ERROR !
          //     }, 3000)
          //   }
          // }

          if (isInviewPart) {
            this.map.setFilter("endsLayer", ["==", "type", "part"])
            this.map.setFilter("centroidLayer", ["==", "type", "part"])
          } else {
            this.map.setFilter("endsLayer", ["==", "type", "day"])
            this.map.setFilter("centroidLayer", ["==", "type", "day"])

            // const isInviewDay = centroidCollection.features
            //   .filter(feature => feature.properties.type === "day")
            //   .some(feature =>
            //     turf.booleanWithin(
            //       feature.properties.bboxPolygon,
            //       mapBoundsPolygon
            //     )
            //   )
            // // console.log("isInviewDay", isInviewDay)

            // if (isInviewDay) {
            //   this.map.setFilter("endsLayer", ["==", "type", "day"])
            //   this.map.setFilter("centroidLayer", ["==", "type", "day"])
            // }
          }
        }
      })
    })

    //////////////////////////
    // END : ON MAP LOADING //
    //////////////////////////

    //////////////////////////
    // ONCE TRACE IS LOADED //
    //////////////////////////

    const onceTraceLoaded = () => {
      // console.log("isSourceLoaded", this.map.isSourceLoaded("traceSource"))

      if (this.map.getSource("traceSource")) {
        if (this.map.isSourceLoaded("traceSource")) {
          // Disable event listener
          this.map.off("sourcedata", onceTraceLoaded)

          // Adjust map canvas position
          this.map.resize()
          this.setState({ loading: false })

          // Fit map to features boundaries
          // this.fitToFeatures(traceCollection, 0)
          this.fitToBbox(bbox, 4000)
        }
      }
    }
    // Enable event listener
    this.map.on("sourcedata", onceTraceLoaded)

    ////////////////////////////////
    // END : ONCE TRACE IS LOADED //
    ////////////////////////////////
  }

  fitToFeatures = (featureCollection, duration) => {
    if (featureCollection.features.length > 0) {
      const bbox = turf.bbox(featureCollection)
      const paddingRatio = 0.05
      bbox.length === 4 &&
        this.map.fitBounds(bbox, {
          padding: {
            left: window.innerWidth * paddingRatio,
            right: window.innerWidth * paddingRatio,
            top: window.innerHeight * paddingRatio,
            bottom: window.innerHeight * paddingRatio,
          },
          duration,
        })
    }
  }

  fitToBbox = (bbox, duration) => {
    const paddingRatio = 0.05
    bbox.length === 4 &&
      this.map.fitBounds(bbox, {
        padding: {
          left: window.innerWidth * paddingRatio,
          right: window.innerWidth * paddingRatio,
          top: window.innerHeight * paddingRatio,
          bottom: window.innerHeight * paddingRatio,
        },
        duration,
      })
  }

  componentDidUpdate(prevProps) {
    const {
      traceCollection,
      centroidCollection,
      endsCollection,
      bbox,
      toggle3d,
      toggleZoom,
      toggleFitBounds,
    } = this.props


    for (let i = 0; i < centroidCollection.features.length; i++) {
      centroidCollection.features[i].properties = endsCollection.features[i].properties;
    }
    // Zoom in out
    // if (prevProps.toggleZoom !== toggleZoom) {
    toggleZoom === "in" && this.map.zoomIn()
    toggleZoom === "out" && this.map.zoomOut()
    // }

    if (prevProps.bbox !== this.props.bbox) {
      if (this.map.getSource("traceSource")) {
        // Update features data
        this.map.getSource("traceSource").setData(traceCollection)
        this.map.getSource("centroidSource").setData(centroidCollection)
        this.map.getSource("endsSource").setData(endsCollection)
        // Fit map to features boundaries
        // this.fitToFeatures(traceCollection, 4000)
        this.fitToBbox(bbox, 4000)
      }
    }

    if (prevProps.toggleFitBounds !== toggleFitBounds) {
      // Fit map to features boundaries
      // this.fitToFeatures(traceCollection, 4000)
      this.fitToBbox(bbox, 4000)
    }

    // 3D view
    if (prevProps.toggle3d !== toggle3d) {
      toggle3d
        ? this.map.flyTo({
            speed: 0.8,
            pitch: 60,
          })
        : this.map.flyTo({
            speed: 0.8,
            pitch: 0,
          })
    }
  }

  render() {
    return (
      <div className="uk-position-cover">
        <div
          style={{ height: "100%" }}
          className="uk-position-cover uk-background-default"
          ref={mapContainer => (this.mapContainer = mapContainer)}
        />
        {this.state.loading && (
          <div className="uk-position-cover uk-background-default uk-animation-fade">
            <div className="uk-position-center uk-text-center">
              <span data-uk-spinner />
              <p>Chargement de la carte...</p>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default ({ bbox, toggle3d, toggleZoom, toggleFitBounds }) => {
  const data = useStaticQuery(graphql`
    query {
      blog: contentfulBlog {
        mapdata
      }
      backgroundLabel: imageSharp(original: { src: { regex: "/rounded/" } }) {
        original {
          src
        }
      }
    }
  `)
  const {
    initialCollection,
    traceCollection,
    centroidCollection,
    endsCollection,
  } = data.blog.mapdata

  return (
    <Fragment>
      <Map
        initialCollection={initialCollection}
        traceCollection={traceCollection}
        centroidCollection={centroidCollection}
        endsCollection={endsCollection}
        bbox={bbox}
        toggle3d={toggle3d}
        toggleZoom={toggleZoom}
        toggleFitBounds={toggleFitBounds}
        backgroundLabel={data.backgroundLabel.original.src}
      />
    </Fragment>
  )
}
